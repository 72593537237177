import { sdk as imageKitSdk, } from '@wix/image-kit';
// @ts-expect-error
import { resizeThumbnail as _resizeThumbnail } from '@wix/communities-blog-universal/dist/src/utils/media/thumbnail';
// @ts-expect-error
export { getImageFilename } from '@wix/communities-blog-universal/dist/src/utils/media/image';
import { isSSR as _isSSR } from './is-ssr';
var WIDTHS_MOBILE = [320, 375, 425, 768];
export var screenWidth = _isSSR() ? 980 : window.screen.width;
export var screenPixelRatio = _isSSR() ? 1 : window.devicePixelRatio;
export var getFormat = function (isSSR) {
    return !isSSR && window.__IS_WEBP_SUPPORTED__ ? 'webp' : 'png';
};
var snapToClosestScreenWidth = function (w) {
    return WIDTHS_MOBILE.reduce(function (closest, v) {
        var d1 = v - w;
        var d2 = closest - w;
        return Math.abs(d1) < Math.abs(d2)
            ? d1 >= 0 || d2 < 0
                ? v
                : closest
            : closest;
    });
};
var getProportionalHeight = function (originalWidth, originalHeight, w) { return w / (originalWidth / originalHeight); };
var getProportionalWidth = function (originalWidth, originalHeight, h) { return h * (originalWidth / originalHeight); };
var adjustSizeByPixelRatio = function (originalWidth, originalHeight, w, h, pixelRatio) {
    var nW = Math.ceil(w * pixelRatio);
    var nH = Math.ceil(h * pixelRatio);
    var isLargerThanOrig = nW > originalWidth || nH > originalHeight;
    return [
        isLargerThanOrig ? originalWidth : nW,
        isLargerThanOrig ? originalHeight : nH,
    ];
};
/**
 * Returns filled `[width, height]` of an image that is best for current device based on given params.
 */
export var getFillSize = function (
/** original image width */
oW, 
/** original image height */
oH, 
/** new image width (the size that image will be shown) */
w, 
/** new image height (the size that image will be shown) */
h, isMobile, 
/** screenWidth, injected for testing, on live taken from window */
sW, 
/** pixelRatio, injected for testing, on live taken from window */
pR) {
    if (sW === void 0) { sW = screenWidth; }
    if (pR === void 0) { pR = screenPixelRatio; }
    if (isMobile && (!w || w > sW)) {
        w = snapToClosestScreenWidth(sW);
    }
    if (w >= oW || h >= oH || !(w || h)) {
        return [oW, oH];
    }
    else if (w && h) {
        var propW = getProportionalWidth(oW, oH, h);
        var propH = getProportionalHeight(oW, oH, w);
        var scaleByW = propH >= h;
        return adjustSizeByPixelRatio(oW, oH, scaleByW ? w : propW, scaleByW ? propH : h, pR);
    }
    else if (w) {
        return adjustSizeByPixelRatio(oW, oH, w, getProportionalHeight(oW, oH, w), pR);
    }
    else if (h) {
        return adjustSizeByPixelRatio(oW, oH, getProportionalWidth(oW, oH, h), h, pR);
    }
};
export var getImageUri = function (image) {
    if (typeof image === 'string') {
        return image;
    }
    if (image) {
        if ('id' in image && image.id !== '') {
            return image.id;
        }
        if ('file_name' in image) {
            return image.file_name;
        }
    }
    return undefined;
};
export var getImageUrl = function (_a) {
    var _b;
    var image = _a.image, _c = _a.maxWidth, maxWidth = _c === void 0 ? 1000 : _c, _d = _a.maxHeight, maxHeight = _d === void 0 ? 1000 : _d, quality = _a.quality, _e = _a.type, type = _e === void 0 ? 'fit' : _e, format = _a.format, blur = _a.blur, _f = _a.encode, encode = _f === void 0 ? false : _f;
    var imageUri = (_b = getImageUri(image)) !== null && _b !== void 0 ? _b : '';
    if (!imageUri) {
        return 'url' in image ? image.url : '';
    }
    var imageSdkScaleFn = {
        fill: imageKitSdk.getScaleToFillImageURL,
        fit: imageKitSdk.getScaleToFitImageURL,
    }[type];
    var scaledImageUrl = imageSdkScaleFn(imageUri, image.width, image.height, maxWidth, maxHeight, {
        quality: quality,
        preferredExtension: format,
        filters: { blur: blur },
        autoEncode: encode,
    });
    return scaledImageUrl;
};
export var resizeThumbnail = function (_a) {
    var pathname = _a.pathname, width = _a.width, height = _a.height, imageHost = _a.imageHost;
    return _resizeThumbnail(imageHost)(pathname, width, height);
};
