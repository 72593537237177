import React from 'react';
import classNames from 'classnames';
import { Image } from '@wix/image';
import {
  getImageUri,
  MediaPlatformImage,
} from '@wix/communities-blog-client-common';
import { withWixImageElement } from '../../hoc/with-wix-image-element';
import { AvatarPlaceholder } from '../icons/avatar-placeholder';
import styles from './fluid-avatar-image.scss';

interface Props {
  image?: MediaPlatformImage;
  size: number;
  ariaLabel: string;
}

const FluidAvatarImage: React.FC<Props> = ({ image, size, ariaLabel }) => {
  const uri =
    getImageUri(image) ?? (image && 'url' in image ? image.url : undefined);

  if (!uri) {
    return (
      <div
        aria-label={ariaLabel}
        className={classNames(styles.container, 'fluid-avatar-image')}
        data-hook="avatar-image"
      >
        <AvatarPlaceholder className={styles.placeholder} />
      </div>
    );
  }

  return (
    <Image
      alt={ariaLabel}
      className={classNames(styles.container, 'fluid-avatar-image')}
      uri={uri}
      displayMode="fill"
      width={size}
      height={size}
      targetWidth={size}
      targetHeight={size}
      data-hook="avatar-image"
    />
  );
};

export default withWixImageElement(FluidAvatarImage);
